@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --adm-radius-s: 4px;
    --adm-radius-m: 8px;
    --adm-radius-l: 12px;
    --adm-font-size-1: 9px;
    --adm-font-size-2: 10px;
    --adm-font-size-3: 11px;
    --adm-font-size-4: 12px;
    --adm-font-size-5: 13px;
    --adm-font-size-6: 14px;
    --adm-font-size-7: 15px;
    --adm-font-size-8: 16px;
    --adm-font-size-9: 17px;
    --adm-font-size-10: 18px;
    --adm-color-primary: #1677ff;
    --adm-color-success: #00b578;
    --adm-color-warning: #ff8f1f;
    --adm-color-danger: #ff3141;
    --adm-color-yellow: #ff9f18;
    --adm-color-orange: #ff6430;
    --adm-color-wathet: #e7f1ff;
    --adm-color-text: #333333;
    --adm-color-text-secondary: #666666;
    --adm-color-weak: #999999;
    --adm-color-light: #cccccc;
    --adm-color-border: #eeeeee;
    --adm-color-background: #ffffff;
    --adm-color-highlight: var(--adm-color-danger);
    --adm-color-white: #ffffff;
    --adm-color-box: #f5f5f5;
    --adm-color-text-light-solid: var(--adm-color-white);
    --adm-color-text-dark-solid: #000000;
    --adm-color-fill-content: var(--adm-color-box);
    --adm-font-size-main: var(--adm-font-size-5);
    --adm-font-family: -apple-system, blinkmacsystemfont, 'Helvetica Neue',
        helvetica, segoe ui, arial, roboto, 'PingFang SC', 'miui',
        'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
    --adm-border-color: var(--adm-color-border);
}
:root {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    --vh: 1vh;
}
html {
    background-color: var(--adm-color-background-body);
}
body {
    color: var(--adm-color-text);
    font-size: var(--adm-font-size-main);
    font-family: var(--adm-font-family);
}
html, body, .microBaseRoot, .microBaseRoot > .linerBackgroundWithLogo  {
    height: 100%;
}
a,
button {
    cursor: pointer;
}
a {
    color: var(--adm-color-primary);
    transition: opacity ease-in-out 0.2s;
}
a:active {
    opacity: 0.8;
}  
.basicLayoutWrap {
    height: calc(100vh - 50px);
    height: calc(var(--vh) * 100 - 50px),
}

@supports (-webkit-touch-callout: none) {
    body {
        min-height: -webkit-fill-available;
    }
}

.popFunctionDom {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
}
#root {
    margin: 0 auto;
    padding: 0px;
    height: 100%;
}

* {
    margin: 0;
    padding: 0;
}

.linerBackgroundWithLogo {
    background: left 0% top 0 / 100% no-repeat url("./assets/login/backage.png"),
      #fff;
  }

.linerBackground {
    background: left 0% top 0 / 100% no-repeat url("./assets/login/backage1.svg"),
      #fff;
  }

input[type="checkbox"] {
    cursor: pointer;
    position: relative;
  }
  
  input[type="checkbox"]::after {
    position: absolute;
    top: -0.0625rem;
    left: -0.0625rem;
    right: -0.0625rem;
    bottom: -0.0625rem;
    content: " ";
    background: #fff no-repeat center / 100% url("./assets/unChecked.svg");
  }
  
  input[type="checkbox"]:checked::after {
    background: #fff no-repeat center / 100% url("./assets/checked.svg");
  }

  micro-app {
    height: 100%;
    micro-app-body {
        height: 100%;
    }
  }

  
  @layer base {
    img {
      @apply inline-block;
    }
  }
  
